<template>
    <ProjectDetailItemEditCard confirmation-message="Projectnaam werd succesvol gewijzigd"
                      title="Bewerk projectnaam"
                      update-store-action="projectsModule/updateProjectName" :update-store-action-payload="{projectName: projectName, projectId: projectId, clientId: clientId}" 
                      :projectId="projectId" :clientId="clientId" :promises.sync="promises">
        <template v-slot:form-fields>
            <v-row>
                <v-col cols="12" md="6">
                <v-text-field v-model="projectName" persistent-placeholder label="Titel" type="text" />
                </v-col>
            </v-row>
        </template>
    </ProjectDetailItemEditCard>
</template>

<script>
import ProjectDetailItemEditCard from "@/views/project/forms/ProjectDetailItemEditCard.vue"


export default {
  name: "ProjectDetailEditTitle",
  props: {
   projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
        projectName: null,
        promises: [
          this.$store.dispatch('projectsModule/fetchProjectName', {clientId: this.clientId, projectId: this.projectId})
            .then((projectName) => this.projectName = projectName)
        ]
    }
  },
  components: {
    ProjectDetailItemEditCard
  },
}
</script>